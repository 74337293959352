@font-face {
  font-family: "Open Sans";
  src: local('Open Sans'), url('./fonts/OpenSans-Regular.ttf') format('truetype');
}

@font-face {
  font-family: "OpenSansCondensed";
  src: local("OpenSansCondensed"), url("./fonts/OpenSansCondensed.ttf") format("truetype");
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

html {
    min-height: 100% !important;
    height: 100%;
}

#root {
    min-height: 100% !important;
    height: 100%;
}

body {
  margin: 0;
  cursor: default;

  font-family: 'Open Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100% !important;
    height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.pagination-menu-container {
  border: 1px solid black;
}

.App {
    min-height: 100% !important;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.footer {
  animation: fadeIn 1s;
  width: 100%;
  padding-top: 0.7em;
  border-top: 1px solid black;
  padding-bottom: 1em;
  background-color: #444444;
  color: white;
  flex-shrink: 0;
}

.footer-container {
  margin-top: 1em;
}

.contact-me-text {
  font-size: 1.5em;
  font-family: "OpenSansCondensed";
  margin-bottom: 20px;
  padding-bottom: 10px;
  border-bottom: 1px solid white;
}

.main-nav {
  color: black;
  border-radius: 25px;
  margin-left: 0.5em;
}

.about-first-section {
  animation: fadeIn 1s;
  margin-top: 110px;
  margin-bottom: 150px;
}

.blog-container {
  animation: fadeIn 1s;
}

.video-container {
  animation: fadeIn 1s;
}

.main-video-container {
  margin-top: 100px;
  margin-bottom: 100px;
}

.about-second-section {
  background-color: #444444;
  color: white;
  animation: fadeIn 1s;
  padding-top: 100px !important;
  padding-bottom: 100px !important;
}


.about-third-section {
  animation: fadeIn 1s;
  margin-top: 150px;
  margin-bottom: 150px;
}

.pagination {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

#no-blogs-text{
    font-size: 40px;
}

.blog-pagination-title {
  text-align: center;
  padding-left: 30px;
}

.blog-pagination-title-active{
  text-align: center;
  padding-left: 30px;
  background-color: rgb(226, 226, 226);
  border-radius: 00.5em;
}

.pagination-items {
  min-height: 500px;
}

.blog-container {
  margin: 0;
  padding-top: 100px;
  padding-bottom: 100px;
}

a {
  text-decoration: none !important;
  color: black !important;
}

a.twitter-link {
  color: white !important;
}

.footer-header {
  margin-bottom: 0.5em;
  font-size: 1.2em;
  font-weight: bold;
}

#active-blog-text {
  white-space: pre-wrap;
}

.active-pagination {
  background-color: rgb(234, 244, 253);
  border-radius: 00.5em;
  padding: 0.2em;
}

.video-gallery {
  padding-top: 90px;
  padding-bottom: 70px;
  margin-top: 1em;
  background-color: rgb(230, 230, 230);
}

.video-title {
  margin-top: 10px;
  font-size: 1.3em;
}

.blog-pagination-title:hover, .blog-pagination-title-active {
  cursor: pointer;
  background-color: rgb(234, 244, 253);
  border-radius: 00.5em;
}

.thomas-header {
  font-family: "OpenSansCondensed";
  font-size: 2.5em;
  letter-spacing: 0.2em;
}

.thomas-image-text {
  margin-bottom: 2%;
}

.image-text-row {
  margin-left: 20% !important;
  margin-right: 20% !important;
}

.thomas-headshot {
  height: 250px;
  width: auto;
  margin-bottom: 3%;
}

.work-item-blue {
  background-color: rgb(245, 245, 245);
  margin-bottom: 0.5%;
  padding-top: 10px;
  padding-bottom: 10px;
}

.work-item-darker-blue {
  background-color: rgb(230, 230, 230);
  margin-bottom: 0.5%;
  padding-top: 10px;
  padding-bottom: 10px;
}

.thomas-headshot-background {
  background-image: url("./images/thomasHeadshotBackground.jpeg");
  padding-bottom: 14em;
  background-repeat: fill;
  border-top: 1px solid black;
  filter: grayscale(60%);
}

.standard-header {
  font-family: "OpenSansCondensed";
  font-size: 2em;
}

.small-header {
  font-family: "OpenSansCondensed";
  font-size: 1.5em;
  margin-bottom: 0em !important;
}

.contact-logo-twitter {
  height: 50px;
  width: auto;
}

.contact-logo-email {
  height: 40px;
  width: auto;
  margin-top: 5px;
}

.small-text {
  margin-bottom: 0em !important;
}

.top-div {
  padding-top: 2%;
  border-bottom: 1px solid black;
  margin-bottom: 1.5%;
}

.active {
  color: grey;
  -webkit-transition: color 1s;
  -moz-transition:    color 1s;
  -ms-transition:     color 1s;
  -o-transition:      color 1s;
  transition:         color 1s;
}

.main-nav:hover {
  color: grey;
  background-color: rgb(226, 226, 226);
}

.uni-logo {
  position: absolute;
  margin-left: -50%;
  padding-top: 0.5em;
  padding-left: 0.5em;
  width: 40px;
  height: auto;
}

@media (min-width: 500px) {
  .contact-me-menu {
    position: absolute !important;
    margin-left: -50%;
    padding-top: 0.5em;
    padding-left: 0.5em;
    font-family: "OpenSansCondensed";
  }
}

.thumbnail {
  height: 240px;
  width: auto;
}

.mainVideo {
  height: 460px;
  width: 840px;
}

@media only screen and (max-width: 768px) {
  [class*="col-"] {
    width: 100%;
  }

  .thomas-header {
    font-size: 1.8em;
  }

  #active-blog-text {
    white-space: pre-wrap;
    margin-bottom: 20px;
    padding-bottom: 10px;
    border-bottom: 1px solid black;
  }

  
  .image-text-row {
    margin-left: 10% !important;
    margin-right: 10% !important;
    font-style: italic;
  }

  .thomas-headshot {
    height: 180px;
    width: auto;
    margin-bottom: 50px;
  }

  .thumbnail {
    height: 200px;
    width: auto;
  }
  
  .mainVideo {
    height: 260px;
    width: auto;
  }

  .contact-me-text {
    font-family: "OpenSansCondensed";
  }

  .footer-header {
    margin-bottom: 0.5em;
    font-size: 1em;
    font-weight: bold;
  }
  
  .navbar {
    flex-direction: column!important;
    font-size: 0.8em;
  }
}
